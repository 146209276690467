import styled from "styled-components"
import { Col } from "styled-bootstrap-grid"
import "./index.css"

const TrendsWrapper = styled.div `
  background-color: ${({
    theme: {
      palette: { darkblue },
    },
  }) => darkblue};
  padding-top: ${({
    theme: {
      header: { mobile },
    },
  }) => mobile};
  min-height: calc(
    100vh -
      ${({
        theme: {
          footer: { mobile },
        },
      }) => mobile}
  );
  color: white;

  @media only screen and (min-width: 576px) {
    padding-top: ${({
      theme: {
        header: { desktop },
      },
    }) => desktop};
    min-height: calc(
      100vh -
        ${({
          theme: {
            footer: { desktop },
          },
        }) => desktop}
    );
  }
`

const TrendsContent = styled.div `
  padding: ${({
    theme: {
      content: {
        padding: { mobile },
      },
    },
  }) => mobile};
  padding-bottom: 31rem;
  padding-top: 6rem;

  @media only screen and (min-width: 576px) and (max-width: 767px) {
    padding: ${({
      theme: {
        content: {
          padding: { tablet },
        },
      },
    }) => tablet};
    padding-bottom: 31rem;
  }

  @media only screen and (min-width: 768px) {
    padding: ${({
      theme: {
        content: {
          padding: { tablet },
        },
      },
    }) => tablet};
    padding-top: 10rem;
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }

  @media only screen and (min-width: 1900px) {
    padding: ${({
      theme: {
        content: {
          padding: { desktop },
        },
      },
    }) => desktop};
    padding-top: 10rem;
    padding-bottom: ${({
      theme: {
        footer: { mobile },
      },
    }) => mobile};
  }
`

const Title = styled.h1 `
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s108};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w100};
`

const Paragraph1 = styled.p `
  margin: ${({ theme: { spacing } }) => spacing.s40} 0;
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s42};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w100};
  line-height: ${({ theme: { spacing } }) => spacing.s50};
`

const Books = styled.div `
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0;
  background-color: #a7a0a8;
  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
  }
`

const Image = styled.img `
  max-width: 80%;

  @media only screen and (min-width: 768px) {
    max-width: 50%;
  }
`

const BankersWrapper = styled.div `
  width: 100%;
  overflow-x: hidden;
  background-color: #a7a0a8;
  position: relative;
  // min-height: 750px;
  padding-bottom: 50px;
  margin-bottom: 0;
  margin-top: 100px;
`

const BankersImage = styled.img `
  max-height: 600px;
  max-width: 300px;
  margin: auto;

  @media only screen and (min-width: 768px) {
    max-width: none;
    margin: 0;
  }
`

const BankersLeft = styled.div `
  top: 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media only screen and (min-width: 1025px) {
    display: block;
    flex: 1;
  }
`

const MichaelImage = styled.img `
  max-height: 150px;
`

const Name = styled.div `
  font-size: 1.6rem;
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w500};
  line-height: ${({ theme: { spacing } }) => spacing.s36};
  margin-top: 15px;
  margin-bottom: 5px;
`

const Position = styled.div `
  font-size: 1.4rem;
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w100};
  line-height: ${({ theme: { spacing } }) => spacing.s36};
  width: 300px;
  text-align: center;

  @media only screen and (min-width: 1200px) {
    text-align: left;
  }
`

const BankersCol = styled(Col)
`
  justify-content: center;
  display: flex;
  align-items: flex-start;
`

export {
    Image,
    Books,
    Paragraph1,
    Title,
    TrendsContent,
    TrendsWrapper,
    BankersImage,
    BankersWrapper,
    Name,
    Position,
    BankersLeft,
    MichaelImage,
    BankersCol,
}
