import React from "react"
import { graphql, StaticQuery } from "gatsby"
import SkuCard from "./SkuCard"
import { loadStripe } from "@stripe/stripe-js"
import {
  BookOrderLine,
  OrderCard1,
  OrderCard2,
  BookOrderIcon,
  BookOrderImage,
  SkuCol,
} from "./style"
import { Row, Col } from "styled-bootstrap-grid"

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY)
let WorldwideSKU = null
let EuropeSKU = null

const Skus = ({ publication }) => {
  const OrderCard = publication ? OrderCard1 : OrderCard2
  const SKUCol = publication ? Col : SkuCol
  return (
    <StaticQuery
      query={graphql`
        query SkusForProduct {
          skus: allStripeSku(sort: { fields: [price] }) {
            edges {
              node {
                id
                currency
                price
                attributes {
                  name
                }
              }
            }
          }
        }
      `}
      render={({ skus }) => (
        <OrderCard>
          <BookOrderIcon>
            <BookOrderImage src={"/order_icon.png"} />
          </BookOrderIcon>
          <BookOrderLine>Order your copy today.</BookOrderLine>
          <Row>
            {skus.edges
              .filter(({ node: sku }) => {
                if (sku.attributes.name.includes("Worldwide")) {
                  WorldwideSKU = sku
                  return false
                }
                if (sku.attributes.name.includes("European")) {
                  EuropeSKU = sku
                  return false
                }
                return true
              })
              .map(({ node: sku }) => (
                <SKUCol md={6}>
                  <SkuCard
                    key={sku.id}
                    sku={sku}
                    stripePromise={stripePromise}
                    publication={publication}
                    WorldwideSKU={WorldwideSKU}
                    EuropeSKU={EuropeSKU}
                  />
                </SKUCol>
              ))}
          </Row>
        </OrderCard>
      )}
    />
  )
}
export default Skus
