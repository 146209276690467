import styled, { keyframes } from "styled-components"
import { Col } from "styled-bootstrap-grid"

const Spin = keyframes`
0% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
`

const Spinner = styled.span`
  font-size: 10px;
  position: relative;
  display: inline-block;
  text-indent: -9999em;
  border-top: 0.5rem solid rgba(255, 255, 255, 0.2);
  border-right: 0.5rem solid rgba(255, 255, 255, 0.2);
  border-bottom: 0.5rem solid rgba(255, 255, 255, 0.2);
  border-left: 0.5rem solid #ffffff;
  transform: translateZ(0);
  animation: ${Spin} 1.1s infinite linear;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;

  &:after {
    border-radius: 50%;
    width: 1rem;
    display: inline-block;
    height: 1rem;
  }
`

const BookType = styled.div`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s21};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w400};
  margin-bottom: 15px;
`

const BookPrice = BookType

const BookGroupType = styled.div`
  margin-bottom: 30px;
  position: relative;
`
const BookBuyButton = styled.button`
  background-color: transparent;
  border: 1px solid ${({ publication }) => (publication ? "white" : "white")};
  cursor: pointer;
  color: ${({ publication }) => (publication ? "white" : "white")};
  font-size: 1rem;
  padding: 5px 20px;
  width: 65px;
  display: block;
  text-decoration: none;
`

const BookOrderImage = styled.img`
  max-width: 100%;
`

const BookOrderIcon = styled.span`
  margin-bottom: 20px;
  display: inline-block;
  max-width: 50px;
`

const OrderCard1 = styled.div`
  margin: 30px;
  margin-top: 80px;;
  color: white;

  @media only screen and (min-width: 768px) {
    width: 430px;
    margin: 0;
    margin-top: 50px;
  }
`

const OrderCard2 = styled.div`
  margin-top: 50px;
  color: white;
  position: relative;
  max-width: 80%;

  @media only screen and (min-width: 768px) {
    max-width: 50%;
    left: 95px;
    width: 764px;
    height: 573px;
  }
`

const BookOrderLine = styled.div`
  font-size: ${({
    theme: {
      font: { sizes },
    },
  }) => sizes.s30};
  font-weight: ${({
    theme: {
      font: { weights },
    },
  }) => weights.w500};
  margin-bottom: 35px;
  max-width: 200px;
`

const SkuCol = styled(Col)`
  @media only screen and (min-width: 768px) {
    max-width: 30%;
  }
`

const DeliveryButtonsWrapper = styled.div`
  top: 0;
  left: 105%;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  margin-top: 20px;

  & > * {
    flex: 1;
    margin-bottom: 15px;
  }

  @media only screen and (min-width: 1200px) {
    position: absolute;
    margin-top: 0;
  }
`

const RadioButton = styled.input`
  vertical-align: middle;
`

const RadioLabel = styled.label`
  font-weight: ${({
    theme: {
      font: { weights },
    },
    delivery,
    current,
  }) => delivery === current && weights.w500};
`

export {
  BookBuyButton,
  BookGroupType,
  BookPrice,
  BookType,
  Spinner,
  BookOrderLine,
  OrderCard1,
  OrderCard2,
  BookOrderIcon,
  BookOrderImage,
  SkuCol,
  DeliveryButtonsWrapper,
  RadioButton,
  RadioLabel,
}
