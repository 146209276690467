import Layout from "../components/layout"
import React from "react"
import HomepageFooter from "../components/footers/homepage_footer"
import Products from "../components/products"
import AwesomeSlider from "react-awesome-slider"
import withAutoplay from "react-awesome-slider/dist/autoplay"
import {
  Books,
  Paragraph1,
  Title,
  TrendsContent,
  TrendsWrapper,
  BankersImage,
  BankersWrapper,
  BankersLeft,
  MichaelImage,
  Name,
  Position,
  BankersCol,
} from "../styles/publication/style"
import { Row, Container } from "styled-bootstrap-grid"
import styled from 'styled-components'

const NavigationSlider = withAutoplay(AwesomeSlider)
const ContentWrapper = styled.div`
  padding-bottom: ${({
    theme: {
      footer: { mobile },
    },
  }) => mobile};

  @media only screen and (min-width: 576px) {
    padding-bottom: ${({
      theme: {
        footer: { desktop },
      },
    }) => desktop};
  }
  background-color: #a7a0a8;
`
const Footer = styled(HomepageFooter)`
  background-color: #a7a0a8;
`
const addLineBreaks = string =>
  string.split('\n\r').map((text, index) => (
    <React.Fragment key={`${text}-${index}`}>
      {text}
      <br />
    </React.Fragment>
  ));

const Trends = () => {
  return (
    <Layout footer={Footer} contentWrapper={ContentWrapper}>
      <TrendsWrapper>
        <TrendsContent>
          <Title>Latest publication</Title>
          <Paragraph1>
            A retrospective by Michael Allen. A guide to retail bank design and
            creativity for financial service companies, improving the customer
            experience and their brand image.
          </Paragraph1>
        </TrendsContent>
        <Books>
          <BankersWrapper>
            <Container>
              <Row>
                <BankersCol lg={6}>
                  <BankersImage src="/bunchofbankers.png" />
                </BankersCol>
                <BankersCol lg={6}>
                  <BankersLeft>
                    <MichaelImage src="/mallen.png" />
                    <Name>Michael Allen</Name>
                    <Position>
                      {addLineBreaks('Founder and ex-Chairman of \n\r Allen International')}
                    </Position>
                    <Products publication />
                  </BankersLeft>
                </BankersCol>
              </Row>
            </Container>
          </BankersWrapper>
          <NavigationSlider
            className="publication-slider"
            play={true}
            bullets={false}
            cancelOnInteraction={false}
            interval={5000}
            media={[
              {
                slug: "slider-two",
                className: "slider-two",
                children: " ",
              },
              {
                slug: "slider-three",
                className: "slider-three",
                children: " ",
              },
              {
                slug: "slider-four",
                className: "slider-four",
                children: " ",
              },
            ]}
          />
          <Products />
        </Books>
      </TrendsWrapper>
    </Layout>
  )
}

export default Trends
