import React, { useState } from "react"
import {
  BookBuyButton,
  BookGroupType,
  BookPrice,
  BookType,
  Spinner,
  DeliveryButtonsWrapper,
  RadioButton,
  RadioLabel,
} from "./style"

const formatPrice = (amount, currency) => {
  // console.log("amount, currency: ", amount, currency)
  let price = (amount / 100).toFixed(2)

  let numberFormatUKCurrency = new Intl.NumberFormat(["en-US"], {
    style: "currency",
    currency: currency,
    currencyDisplay: "symbol",
  })
  return [numberFormatUKCurrency.format(price)].join(" / ")
}

const SkuCard = ({
  sku,
  stripePromise,
  publication,
  WorldwideSKU,
  EuropeSKU,
}) => {
  const [isLoading, setLoading] = useState(false)
  const [delivery, setDelivery] = useState("uk")
  const countries = ["GB"]
  const EUcountries = ["AT", "BE", "BG", "HR", "CY", "CZ", "DK", "EE", "FI", "FR", "DE", "GR", "HU", "IE", "IT", "LV", "LT", "LU", "MT", "NL", "PL", "PT", "RO", "SK", "SI", "ES", "SE"]
  const WWcountries = ["AC", "AD", "AE", "AF", "AG", "AI", "AL", "AM", "AO", "AQ", "AR", "AU", "AW", "AX", "AZ", "BA", "BB", "BD", "BF", "BH", "BI", "BJ", "BL", "BM", "BN", "BO", "BQ", "BR", "BS", "BT", "BV", "BW", "BY", "BZ", "CA", "CD", "CF", "CG", "CH", "CI", "CK", "CL", "CM", "CN", "CO", "CR", "CV", "CW", "DJ", "DM", "DO", "DZ", "EC", "EG", "EH", "ER", "ET", "FJ", "FK", "FO", "GA", "GB", "GD", "GE", "GF", "GG", "GH", "GI", "GL", "GM", "GN", "GP", "GQ", "GS", "GT", "GU", "GW", "GY", "HK", "HN", "HT", "ID", "IL", "IM", "IN", "IO", "IQ", "IS", "JE", "JM", "JO", "JP", "KE", "KG", "KH", "KI", "KM", "KN", "KR", "KW", "KY", "KZ", "LA", "LB", "LC", "LI", "LK", "LR", "LS", "LY", "MA", "MC", "MD", "ME", "MF", "MG", "MK", "ML", "MM", "MN", "MO", "MQ", "MR", "MS", "MU", "MV", "MW", "MX", "MY", "MZ", "NA", "NC", "NE", "NG", "NI", "NO", "NP", "NR", "NU", "NZ", "OM", "PA", "PE", "PF", "PG", "PH", "PK", "PM", "PN", "PR", "PS", "PY", "QA", "RE", "RS", "RU", "RW", "SA", "SB", "SC", "SG", "SH", "SJ", "SL", "SM", "SN", "SO", "SR", "SS", "ST", "SV", "SX", "SZ", "TA", "TC", "TD", "TF", "TG", "TH", "TJ", "TK", "TL", "TM", "TN", "TO", "TR", "TT", "TV", "TW", "TZ", "UA", "UG", "US", "UY", "UZ", "VA", "VC", "VE", "VG", "VN", "VU", "WF", "WS", "XK", "YE", "YT", "ZA", "ZM", "ZW", "ZZ"]

  const redirectToCheckout = async (event, sku, countries, quantity = 1) => {
    event.preventDefault()
    const stripe = await stripePromise
    const { error } = await stripe.redirectToCheckout({
      items: [{ sku, quantity }],
      billingAddressCollection: "auto",
      shippingAddressCollection: {
        allowedCountries: countries
      },
      successUrl: `${window.location.origin}/latest-publication/`,
      cancelUrl: `${window.location.origin}/`,
    })
    if (error) {
      // console.warn("Error:", error)
    }
  }

  const skuID =
    delivery === "uk"
      ? sku.id
      : delivery === "europe"
      ? EuropeSKU.id
      : WorldwideSKU.id
  const skuPrice = "3500"
  const skuCurrency =
    delivery === "uk"
      ? sku.currency
      : delivery === "europe"
      ? EuropeSKU.currency
      : WorldwideSKU.currency
  const skuCountries =
      delivery === "uk"
        ? countries
        : delivery === "europe"
        ? EUcountries
        : WWcountries
  const skuName = "Hardcopy/printed version"

  const isHardcopy = sku.attributes.name.includes("Hardcopy")
  const finalID = isHardcopy ? skuID : sku.id
  const finalPrice = isHardcopy ? skuPrice : sku.price
  const finalCurrency = isHardcopy ? skuCurrency : sku.currency
  const finalName = isHardcopy ? skuName : sku.attributes.name
  const showButtons = isHardcopy ? true : false

  return (
    <React.Fragment>
      <BookGroupType>
        <BookType>{finalName} </BookType>
        <BookPrice>{formatPrice(finalPrice, finalCurrency)}</BookPrice>

        <BookBuyButton
          {...(!isHardcopy && { as: "a" })}
          {...(!isHardcopy && {
            href:
              "https://www.amazon.com/Total-Bunch-Bankers-Banks-Branches-ebook/dp/B088KQV1QT/ref=sr_1_1?dchild=1&keywords=A+Total+bunch+of+bankers&qid=1589482485&sr=8-1",
          })}
          publication={publication}
          {...(isLoading && { disabled: true })}
          {...(isHardcopy && {
            onClick: event => {
              setLoading(true)
              window.location.assign(process.env.WP_CHECKOUT_URL)
              // redirectToCheckout(event, finalID, skuCountries)
            },
          })}
        >
          {isLoading ? <Spinner /> : "BUY"}
        </BookBuyButton>
      </BookGroupType>
    </React.Fragment>
  )
}

export default SkuCard
